
.gallery_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.gallery_container article {
    border: 1rem;
    transition: var(--transition);
    margin: .4rem;
}
.gallery_container article:hover {
    border-color: var(--color-gray-500);
}


/* media queries md screen */
@media screen and (max-width:1024px) {
    .gallery_container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    .gallery_container article {
        border: 1rem;
    }

}

/* mobile size */
@media screen and (max-width:600px) {
    .gallery_container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}