*{
    margin: 0;
    padding: 0;

    box-sizing: border-box;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

main.table {
    width: 80vw;
    height: 100vh;
    /* background-color: #fff5; */
    backdrop-filter: blur(7px);
    box-shadow: 0.4rem 0.8rem #0005;
    border-radius: .8rem;
    margin: 0 auto;
    overflow: hidden;
}
.table_header{
    width: 100%;
    height: 10%;
    background-color: rgba(78, 75, 217, 0.267);
    padding: .8rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.table_header a {
    text-decoration: none;
}
.table_header .input-group{
    width: 45%;
    height: 100%;
    /* background: #fff5; */
    padding: 0.8rem;
    border-radius: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.table_header .input-group input{
    width: 100%;
    padding: .5rem .3rme;
    background: transparent;
    outline: none;
    border: none;
    color: white;
}
.table_header .input-group img{
    width: 1.2rem;
    height: 1.2rem;
}
.table_header .input-group:hover{
    width: 55%;
    background-color: rgba(61, 103, 227, 0.467);
    box-shadow: 0 .1rem .4rem #0002;
}
.table_body{
    width: 95%;
    max-height: calc(89% - 1.6rem);
    margin: .8rem auto;
    border-radius: .6rem;

    overflow: auto;
}
.table_body::-webkit-scrollbar{
    width: 0.2rem;
    height: 0.2rem;
}
.table_body::-webkit-scrollbar-thumb{
    border-radius: .2rem;
    background-color: #0004;
    visibility: hidden;
}
.table_body:hover::-webkit-scrollbar-thumb{
    visibility: visible;
}
table{
    width: 100%;
}

td img {
    width: 36px;
    height: 36px;
    margin-right: .5rem;
    border-radius: 50%;
    vertical-align: middle;
}
table , th, td {
    border-collapse: collapse;
    padding: 1rem;
    text-align: left;
}
thead th{
    position: sticky;
    top: 0;
    left: 0;
    background-color: rgb(88, 101, 186);
    cursor: pointer;
}

tbody tr:nth-child(even){
    background-color: rgba(87, 98, 201, 0.795);
}
tbody tr{
    --delay: .1s;
    transition: .5s ease-in-out var(--delay) ,background-color 0s;
}
tbody tr.hide{
    opacity: 0;
    transform: translateX(100%);
}
tbody tr:hover {
    background-color: rgba(56, 82, 226, 0.4);
}
tbody tr td,
tbody tr td p,
tbody tr td img{
    transition: .2s ease-in-out;
}

tbody tr.hide td,
tbody tr.hide td p{
    opacity: 0;
    font:0/0 sans-serif;
    transition: .2s ease-in-out .5s;
}
.status{
    padding: .05rem 0.4rem;
    border-radius: .6rem;
    text-align: center;
}

tbody tr.hide td,
tbody tr.hide td img{
    padding: 0px;
    margin: 0;
    width: 0;
    height: 0;
    transition: .2s ease-in-out .5s;
}


.status.delievered {
    background: rgb(213, 243, 168);
}
.status.cancled{
    background: rgb(235, 130, 130);
}
.status.readmore{
    background: rgb(111, 126, 224);
    cursor: pointer;
}
.status.shipped{
    background: rgb(185, 168, 247);
}
@media (max-width:1000px) {
    td:not(:first-of-type){
        min-width: 10rem;
    }
    main.table {
        width: 100vw;
    }
}


/* =========sorting ======= */
thead th span.icon-arrow {
    display: inline-block;
    width: 1.3rem;
    height: 1.3rem;
    border: 1.4px solid rgb(202, 9, 209);
    border-radius: 50%;
    text-align: center;
    font-size: 1rem;
    margin-left: .5rem;
    
}
thead th.asc span.icon-arrow {
    border: 1.4px solid rgb(202, 9, 209);
    transform: rotate(180deg);
}
thead th.active span.icon-arrow {
    background-color: rgb(245, 6, 233);
    color: black;
}
thead th:hover span.icon-arrow {
border: 1.4px solid black;
background-color: white;
}

thead th.active,tbody td.active {
    color: rgb(110, 1, 92);
}
