

/* ====programs ======= */
.programs {
    margin-top: 4rem;

}
.programs_wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    margin-top: 4rem;
}
.programs_programs a {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.programs_programs:hover a {
   background: var(--color-secondary);
   color: var(--color-gray-600);
}


/*+============= Values  ======*/
.values_container {
    display: grid;
    grid-template-columns: 38% 50%;
    gap: 12%;
}
.values_image {
    filter:saturate(0.25);
    transition: var(--transition);
}
.values_image:hover {
    filter: saturate(1);
}
.values_right > p {
    margin: 1.5rem 0 5rem;
}
.values_wrapper {
    display: grid;
    grid-template-columns: 1fr  1fr;
    gap: 3.4rem 2.4rem;

}
.card.values_value {
    padding-top: 3.5rem;
    text-align: left;
    position: relative;
}
.card.values_value  span {
    position: absolute;
    top: -1.5rem;
}


/* ========FAQS======= */
.faqs_wrapper {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 1.5rem;
    align-items: flex-start;    
}
.faq {
    background: var(--color-gray-500);
    padding: 2rem;
    border-radius: 1rem;
    cursor: pointer;
}
.faq div {
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.faq_icon {
    background: transparent;
    color: var(--color-gray-100);
    font-size: 1.5rem;
}
.faq p {
    margin-top: 1.5rem;
}




/*=========== media ===========*/
@media screen and (max-width:1024px) {


    /* ==programs=== */
    .programs_wrapper{
        grid-template-columns: 1fr 1fr;

    }
    /* ==Values=== */
    .values_container{
        grid-template-columns: 1fr;
        justify-content: center;
        gap: 4rem;
    }
    .values_wrapper{
        gap: 4rem 0.3rem;
    }
    .values_image{
        display: none;
    }

    /* FAQs */
    .faqs_wrapper{
        grid-template-columns: 1fr;
    }

    /* footer */
    .footer_container{
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
    }
    
}
/*=========== media mobile small ===========*/
@media screen and (max-width:600px) {

    /* programs */
    .programs_wrapper{

        grid-template-columns: 1fr;
        gap: 2rem;
        margin-top: 3rem;
    }
    .programs_programs{
        width: 84%;
        margin: 0 auto;
    }

    /* values */
    .values_wrapper{
        grid-template-columns: 1fr;
        gap: 3rem;
    }
    .card.values_value{
        width: 84%;
        margin-inline:auto;
    }

    /* FAQs */
    .faqs_wrapper{
        gap: 1rem;
        margin-top: 3rem;
    }


    /* footer */
    footer {
        margin-top: 7rem;
    }
    .footer_container{
        grid-template-columns: 1fr;
        gap: 3rem;
    }
    .footer_container article {
        align-items: center;
    }
    .footer_container article p {
        text-align: center;
    }
}