nav {
    height: 5rem;
    width: 100vw;
    background: var(--color-primary);
    display: grid;
    position: fixed;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    
}


/* only shows on medium and small */
.nav_toggle-btn{
    display: none;
}

.nav_container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

}

.logo {
    width: 7rem;
    display: block;
}
.nav_links {
    display: flex;
    gap: 3.5rem;
    align-items: center;
}
.nav_links a{
    transition: var(--transition);
}
.nav_links a:hover {
    color: var(--color-secondary);
}
.active-nav {
    position: relative;
}
.active-nav::after {
    content: '';
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    background:var(--color-primary);
    position: absolute;
    left: calc(50% - 0.6rem);
    transform:  rotate(45deg);
    margin-top: 0.9rem;

}

/* media queries medium ans small screens */
@media screen and (max-width:1024px) {
    .nav_toggle-btn {
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }
    .nav_toggle-btn svg {
        color: var(--color-gray-100);
    }
    .nav_links {
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
    }
    .active-nav {
        display: none;
    }
    .nav_links li {
        height: 4rem;
        width: 100%;
        box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.4);
        animation: navAnimation 600ms ease forwards;
        transform:  rotateX(90deg);
        opacity: 0;
        transform-origin: top;
    }
    .nav_links li a {
        background-color: var(--color-primary);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
    }
    .nav_links li:nth-child(2){
        animation-delay: 200ms;
    }
    .nav_links li:nth-child(3){
        animation-delay: 400ms;
    }
    .nav_links li:nth-child(4){
        animation-delay: 600ms;
    }
    .nav_links li:nth-child(5){
        animation-delay: 800ms;
    }
    .nav_links li:nth-child(6){
        animation-delay: 1s;
    }
    /* .nav_links li:nth-child(7){
        animation-delay: 1200ms;
    }
    .nav_links li:nth-child(8){
        animation-delay: 1400ms;
    } */
    @keyframes navAnimation {
        to {
            transform: rotateX(0);
            opacity: 1;
        }
    }
    .show_nav {
        display: flex;
    }
    .hide_nav {
        display: none;
    }
}