*, *::before, *::after{
    margin: 0;
     padding:0 ;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    
    }
    :root{
        --primary-hue:210;
        --gray-hue:240;
        --color-primary: hsl(var(--primary-hue),100% , 50%);
        --color-primary-variant: hsl(var(--primary-hue),100% , 65%);
        
        --color-info: #01C3D9;
    
        --color-secondary: #ffb116;
        --color-gray-100: hsl(var(--gray-hue), 47%, 94%);
        --color-gray-200: hsl(var(--gray-hue),26%, 75%);
        --color-gray-300: hsl(var(--gray-hue),24%, 64%);
        --color-gray-400: hsl(var(--gray-hue),16%, 41%);
        --color-gray-500: hsl(var(--gray-hue),44%, 25%);
        --color-gray-600: hsl(var(--gray-hue),48%, 15%);
    
        --container-width-lg:80%;
        --container-width-md:90%;
    
        --transition: all 700ms ease;
    }
    
    /* general styles */
    
    body{
        color: var(--color-gray-200);
        line-height: 1.7;
        overflow-x: hidden;
        background: var(--color-gray-600) url('./images/bg_texture.png');
    }
    .container {
        width: var(--container-width-lg);
        max-width: 1920px;
        margin-inline: auto;
        
    }
    h1, h2 , h3, h4, h5 {
        line-height: 1.2;
        /* color: var(--color-gray-100); */
    }
    h1{
        font-size: 3rem;
    
    }
    h2{
        font-size: 2rem;
    }
    a{
        color: var(--color-gray-100);
    }
    img{
        display: block;
        object-fit: cover;
        width: 100%;
    }
    
    
    
    .btn {
        color: var(--color-gray-100);
        width: fit-content;
        margin: 0 auto;
        padding: 0.9rem 2rem;
        background: var(--color-primary);
        border-radius: 1.5rem;
        transition: var(--transition);
    }
    .btn:hover {
        background: var(--color-secondary);
        color: var(--color-gray-600);
    
    }
    .btn.lg{
        padding: 1.2rem 3rem;
        border-radius: 2rem;
        font-size: 1.1rem;
    }
    .btn.sm {
        padding: 0.4rem 1.2rem;
        font-size: 1.1rem;
    }
    
    
    
    section {
        margin-top: 10rem;
    }
    
    .section_head {
        
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    .section_head span {
        background: var(--color-gray-500); 
        padding: 0.8rem;
        border-radius: 1rem;
        color: var(--color-secondary);
        font-size: 1.5rem;
    }
    
    
    .card {
        background: var(--color-gray-500);
        border: 2px solid transparent;
        text-align: center;
        padding: 3rem 2rem;
        border-radius: 0 3rem 0 3rem;
        transition: var(--transition);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .card:hover{
        background: transparent;
        border-color: var(--color-gray-400);
        cursor: default;
    }
    .card span {
        width: 3rem;
        height: 3rem;
        background:var(--color-gray-600);
        font-size: 1.5rem;
        padding: .8rem;
        display: grid;
        place-items: center;
        margin-bottom: 1.5rem;
        margin-inline: auto;
        border-radius: 1rem;
        transition: var(--transition);
    }
    .card:hover span{
        background: var(--color-secondary);
        color: var(--color-gray-600);
    }
    .card small {
        margin-top: 1rem;
        display: block;
        font-weight: 300;
    }
    
    
    
    
    
    .header {
        margin-top: 5rem;
        height: 20rem;
        overflow: hidden;
        border-bottom: 2px solid var(--color-gray-400);
    }
    .header_container {
        width: 100%;
        height: 100%;
        position: relative;
        display: grid;
        place-items: center;
        background: black;
    
    }
    .header_container-bg{
        position: absolute;
        width: 100%;
    }
    .header_container-bg img{
        opacity: .5;
    }
    .header_content{
        position: relative;
        width: 44%;
        margin: 0 auto;
        text-align: center;
        color: var(--color-gray-100);
    }
    .header_content h2 {
        margin-bottom: 1rem;
    }
    .header_content p {
        color: var(--color-gray-100);
        font-weight: bold;
    }
    

    /* ===Alphabet Cart==== */

    .audio_sorce{
        display: none;
    }
    .btn_container .btn_content {
        width: 7rem;
        height: 5rem;
        
    }

    .alphabet_btn {
        width: 100%;
        background: var(--color-gray-600);
        border: 1px solid var(--color-gray-100);
        font-size: 1.3rem;
        cursor: pointer;
    }
    
    .alphabet_btn.success {
        background: var(--color-gray-400);
        border-color: rgb(233, 250, 2);
        font-weight: bold;
        color: var(--color-gray-600);
    }
    .alphabet_btn:hover {
        border-color: var(--color-info);
        background: var(--color-gray-500);
        font-size: 1.5rem;
        font-weight: bold;
        color: var(--color-secondary);
    }



/* ===sidebar==== */
nav.sidebar_container {
    left: 0;
    margin-top: calc(100vh/5);
    margin-bottom: auto;
    background: transparent;
    width: 5rem;
    height: 20rem;
    display: inline-flex;
    justify-content: start;
    align-items: center;
}

nav.sidebar_container li {
    left: 0;
    width: 2.5rem;
    height: 1.5rem;
    background: var(--color-primary);
    color: var(--color-gray-600);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-gray-100);
    border-radius: .4rem;
    margin-bottom: 0.4rem;
    transition: var(--transition);
}
nav.sidebar_container li.sidebar_item {
    margin-left: -3rem;
    width: 3rem;
    height: 2rem;
}

nav.sidebar_container li:hover {
    cursor: pointer;
    background: var(--color-gray-600);
    color: var(--color-secondary);
}

.sidebar_container .sidebar_item.move {
    left: 0;
    margin-left: 0;
    width: 7rem;
    
}





/* ==========footer============ */
footer{
    background: var(--color-primary);
    margin-top: 5rem;
    padding-top: 4rem;
    font-size: 0.9rem;
    color: var(--color-gray-100);
}
.footer_container {
    display:grid;
    grid-template-columns: 26rem 1fr 1fr 1fr;
    gap: 6rem;
}
.footer_container article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}
.footer_container article p {
    margin-top: .5rem;
}
.footer_container article h4 {
    margin-bottom: .6rem;
    font-size: 1rem;
}
.footer_socials {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;

}
.footer_socials a {
    background: var(--color-gray-100);
    padding: 0.7rem;
    border: 1px solid transparent;
    border-radius: .6rem;
    transition: var(--transition);
}
.footer_socials a svg {
    color: var(--color-gray-600);
}
.footer_socials a:hover {
    border-color: var(--color-gray-100);
    background: transparent;
}
.hightLightText{
    font-weight: bold;
    color: var(--color-gray-600);
}
.footer_copyright {
    color:var(--color-gray-100) ;
    text-align: center;
    padding: 1.5rem 0;
    border-top: 2px solid var(--color-primary-variant);
    margin-top: 5rem;
}

/* Shan-Ni National Font */
@font-face {
    font-family: ShanNiFont;
    /* src: url('../../public/fonts/ShanniNational-Regular-281222-v.1.002.ttf'); */
    src: url('./shanniFont130924.ttf');
  }
  .ShanNiFont,.card {
    font-family: ShanNiFont;
    font-weight: bolder;
  }


.faq_icon {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
}
    /*=========== media ===========*/
    @media screen and (max-width:1024px) {
        body {
            .container {
                width: var(--container-width-md);
            }
            h1 {
                font-size: 2rem;
            }
            h2{
                font-size: 1.6rem;
            }
            .header_content {
                width: 74%;
            }
        }

        
    .btn_container.voice-6-tone {
        margin-left: -1.2rem;
    }
    .btn_content.voice-6-tone{
        
        display: flex;
        justify-self:center;
        align-items: center;
        width: 6rem;
        height: 4rem;
        margin-right: 1rem;
    }
    .alphabet_btn.voice-6-tone{
        padding: 0;
        width: 5rem;
        height: 3rem;
    }
    }
    /*=========== smoblile and small ===========*/
    @media screen and (max-width:600px) {
        body {
            .container {
                width: var(--container-width-md);
            }
            h1 {
                font-size: 2.2rem;
                line-height: 1.3;
            }
            section {
                margin-top: 7rem;
            }
            .header {
                height: fit-content;
            }
            .header_content{
                width: var(--container-width-md);
                padding: 3rem 0;
            }
            .header_content p {
                font-size: 0.8rem;
            }
        }
        
    .btn_container.voice-6-tone {
        margin-left: -1.2rem;
    }
    .btn_content.voice-6-tone{
        width: 4.7rem;
        height: 4rem;
        margin-right: .2rem;
    }
    .alphabet_btn.voice-6-tone{
        padding: 0;
        width: 3rem;
        height: 2rem;
        font-size: 10px;
    }
        
    }